<template>
    <admin-dashboard-layout>
        <template v-slot:left-tools>
            <timeframe-select/>
        </template>
        <template v-slot:right-tools>
            <v-btn @click="$router.push({name: 'admin.social-graphics.create'})" v-text="'Create'"/>
        </template>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="socialGraphics"
            :items-per-page="5"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | formattedDate }}
            </template>
            <template v-slot:item.tags="{ item }">
                <v-chip x-small  v-for="tag in item.tags" :key="`tag-`+tag.id">
                    {{ tag.name }}
                </v-chip>
            </template>
            <template v-slot:item.lang="{ item }">
                <v-chip x-small v-text="item.language"/>
            </template>
            <template v-slot:item.file_size="{ item }">
                <template v-for="(size, entity) in item.file_size">
                    <v-chip x-small :key="entity">{{ entity }}: {{ size }}</v-chip>
                </template>
            </template>
            <template v-slot:item.actions="{ item }">
                <delete-dialog :model="item" type="infographics" @success="init" />
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import DeleteDialog from "@/components/dialogs/DeleteDialog";
    import SocialGraphic from "@/models/SocialGraphic";
    import TimeframeSelect from "@/views/partials/TimeframeSelect";

    export default {
        name: "Index",
        components: {TimeframeSelect, DeleteDialog, AdminDashboardLayout},
        data: function () {
            return {
                loading: false,
                socialGraphics: [],
                headers: [
                    {
                        text: '# ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    { text: 'Name', value: 'name' },
                    { text: 'Notes', value: 'notes' },
                    { text: 'Hashtags', value: 'tags' },
                    { text: '# Downloads', value: 'downloads_count' },
                    { text: '# Shares', value: 'shares_count' },
                    { text: 'Rank', value: 'rank' },
                    { text: 'Languages', value: 'lang' },
                    { text: 'Created at', value: 'created_at' },
                    { text: 'File size', value: 'file_size' },
                    { text: 'Actions', value: 'actions' },
                ],
            }
        },
        methods: {
            handleClickRow(infographic) {
                this.$router.push({name: 'admin.social-graphics.show', params: {id: infographic.id}})
            },
            async init() {
                this.loading = true
                await this.loadTable()
                this.loading = false
            },
            async loadTable() {
                let query = SocialGraphic.include(['tags']);

                if (this.$route.query.lastDays) {
                    query = query.where('lastDays', this.$route.query.lastDays);
                }

                this.socialGraphics = await query.get()
            }
        },
        mounted() {
          this.init()
        },
        watch: {
            "$route.query": async function (val, oldVal) {
                if (val !== oldVal) {
                    await this.init()
                }
            }
        }
    }
</script>

<style scoped>

</style>
